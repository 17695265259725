import {
  interserctionObserver
} from "./chunk.C3CSSY4S.js";
import {
  n
} from "./chunk.BBN2ZB42.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/mixins/ChangesBackgroundColor.ts
function ChangesBackgroundColor(baseClass) {
  const _ChangesBackgroundColorMixin = class _ChangesBackgroundColorMixin extends baseClass {
    constructor() {
      super(...arguments);
      this._availableColors = {
        "royalblue-light": "231, 232, 238",
        smoke: "113, 124, 125",
        // '#717c7d',
        "smoke-light": "241, 242, 242",
        // '#f1f2f2',
        stone: "176, 170, 126",
        // '#b0aa7e',
        "stone-light": "247, 247, 242",
        // '#f7f7f2',
        "violet-grey": "123, 100, 105",
        // '#7b6469',
        "violet-light": "242, 239, 240"
        // '#f2eff0',
      };
    }
    update(changedProperties) {
      super.update(changedProperties);
      document.body.classList.remove("sb-show-main");
      if (this.changesBodyBackgroundColor) {
        const intersectionObserverOptions = {
          rootMargin: "0px",
          threshold: 0.1
        };
        interserctionObserver(
          this,
          intersectionObserverOptions,
          (e) => this._handleIntersection(e)
        );
      }
    }
    _clearBackground() {
      if (document.body.style.backgroundColor) {
        document.body.style.removeProperty("background-color");
        document.body.style.removeProperty("--body-background-color");
      }
    }
    /**
     * override this method to specify if the backgroundColor should fade in when already in viewport
     */
    _enableAnimation() {
      return true;
    }
    _handleIntersection(e) {
      const intersectingEntries = e.filter((entry) => entry.isIntersecting);
      if (intersectingEntries.length > 0) {
        const maxIntersectingEntry = intersectingEntries.reduce(
          (prev, current) => prev.intersectionRatio > current.intersectionRatio ? prev : current
        );
        if (this._enableAnimation() || maxIntersectingEntry.boundingClientRect.top < 0) {
          document.body.classList.add("animatebackground");
        } else {
          document.body.classList.remove("animatebackground");
        }
        _ChangesBackgroundColorMixin._currentBackgroundColor = this.changesBodyBackgroundColor;
        this._setBackground();
      } else if (_ChangesBackgroundColorMixin._currentBackgroundColor === this.changesBodyBackgroundColor) {
        _ChangesBackgroundColorMixin._currentBackgroundColor = void 0;
        this._clearBackground();
      }
    }
    _setBackground() {
      document.body.style.backgroundColor = `rgb(${this._availableColors[this.changesBodyBackgroundColor]})`;
      document.body.style.setProperty(
        "--body-background-color",
        this._availableColors[this.changesBodyBackgroundColor]
      );
    }
  };
  __decorateClass([
    n()
  ], _ChangesBackgroundColorMixin.prototype, "changesBodyBackgroundColor", 2);
  let ChangesBackgroundColorMixin = _ChangesBackgroundColorMixin;
  return ChangesBackgroundColorMixin;
}

export {
  ChangesBackgroundColor
};
